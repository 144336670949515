// 引入base样式
import 'babel-polyfill'
import '@/assets/css/base.less'

// 引入animate
import 'animate.css'
import 'wowjs/css/libs/animate.css'

require('es6-promise').polyfill()

import Vue from 'vue'
import App from './App.vue'
// import 'default-passive-events'

// 引入element-ui
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';



import { CountDown } from 'vant';
Vue.use(CountDown);


import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: 'WzdZM1Bm84osdB2gfzcGpuqjxPdowoMp'
})

/* 富文本*/
import tinymce from 'tinymce'
import VueTinymce from '@packy-tang/vue-tinymce'
Vue.prototype.$tinymce = tinymce;
Vue.use(VueTinymce);
/* 二次封装  */
// import vueTinymceEditor from '@/components/Tinymce/Tinymce.vue';
// Vue.component('TinymceEditor', vueTinymceEditor);


// 注册element-ui
Vue.use(ElementUI, { size: 'small', zIndex: 60000 })


// base64
import Base64 from './utils/base64'
Vue.prototype.$Base64 = Base64;



Vue.prototype.isLogin = function(){
  console.log("全局公共方法")
};


// 刷新页面回到顶部
router.afterEach((to,from,next)=>{
  window.scrollTo(0,0);
})


// 引入路由实例
import router from '@/router'

// 引入store实例
// import store from '@/store'

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router,
  // store
}).$mount('#app')
