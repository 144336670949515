<template>
  <router-view></router-view>
</template>

<script>
// import { wxjssdkparams, adlist } from "@/api/axios/axios.api";
import storage from "@/utils/storage";

export default {
  data() {
    return {
      jsSdk: "",
      user: "",
      timer: null,
      screenWidth: document.body.clientWidth, //初始化宽度
      // clientHeight: document.body.clientHeight
    };
  },
  // 宽度: document.body.clientWidth
  // 高度: document.body.clientHeight

  methods: {},
  mounted() {
    this.$nextTick(() => {
      this.screenWidth = document.documentElement.clientWidth;
      console.log(this.screenWidth);
      let height = document.documentElement.clientHeight
      if (window.outerHeight === screen.availHeight && window.outerWidth === screen.availWidth) {
        storage.height_top = 1
      } else {
        // storage.height_top = -5200
      }
      window.onresize = function () {
        // this.resize();
        this.screenWidth = document.body.clientWidth;
      }.bind(this);
    });
  },
  created() { 
    // this.$router.go(0);
    window.scroll(0, 0);
  },
  watch: {
    screenWidth(e) {
      if (!this.timer) {
        this.timer = true;
        let _this = this;
        setTimeout(function () {
          // _this.$router.go(0);
          // window.scroll(0, 0);
          _this.timer = false;
        }, 500);
        console.log(e);
      }
    }
  }
};
</script>

<style lang="less" scoped>
* {
  padding: 0;
  margin: 0;
}

html,
body,
#app {
  width: 100%;
  min-width: 1300px;
  height: 100%;

  // "> 1%",
  //   "last 2 versions",
  //   "not dead"

//   >1%
// last 2 versions
// not ie<=11
}
</style>
