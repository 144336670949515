export default {
    // 管理token缓存
    get token() {
        return sessionStorage.getItem('token')
    },

    set token(token) {
        sessionStorage.setItem('token', token)
    },

    // 管理401
    get status401() {
        return sessionStorage.getItem('status401')
    },

    set status401(status401) {
        sessionStorage.setItem('status401', status401)
    },

    // 管理cookie
    get cookie() {
        return sessionStorage.getItem('cookie')
    },

    set cookie(cookie) {
        sessionStorage.setItem('cookie', cookie)
    },


    // 加密key
    get secretKey() {
        return sessionStorage.getItem('secretKey')
    },

    set secretKey(secretKey) {
        sessionStorage.setItem('secretKey', secretKey)
    },

    // 角色信息
    get role() {
        return sessionStorage.getItem('role')
    },

    set role(role) {
        sessionStorage.setItem('role', role)
    },

    // 找人才首页tab标签
    get tab() {
        return sessionStorage.getItem('tab')
    },

    set tab(active) {
        sessionStorage.setItem('tab', active)
    },

    // 首页tab标签
    get homeTab() {
        return sessionStorage.getItem('homeTab')
    },

    set homeTab(active) {
        sessionStorage.setItem('homeTab', active)
    },

    // 服务商id
    get companyId() {
        return sessionStorage.getItem('companyId')
    },

    set companyId(companyId) {
        sessionStorage.setItem('companyId', companyId)
    },

    // 管理用户信息缓存
    get user() {
        return JSON.parse(sessionStorage.getItem('user'))
    },

    set user(user) {
        sessionStorage.setItem('user', JSON.stringify(user))
    },

    // 管理用户详细信息缓存
    get userInfo() {
        return JSON.parse(sessionStorage.getItem('userInfo'))
    },

    set userInfo(userInfo) {
        sessionStorage.setItem('userInfo', JSON.stringify(userInfo))
    },

    // 管理倒计时缓存
    get count() {
        return sessionStorage.getItem('count')
    },

    set count(count) {
        sessionStorage.setItem('count', count)
    },

    // get count() {
    //     return JSON.parse(localStorage.getItem('count'))
    // },

    // set count(count) {
    //     localStorage.setItem('count', JSON.stringify(count))
    // },

    // 管理选稿缓存
    get takbidList() {
        return JSON.parse(sessionStorage.getItem('takbidList'))
    },

    set takbidList(takbidList) {
        sessionStorage.setItem('takbidList', JSON.stringify(takbidList))
    },

    // 管理服务商信息缓存
    get companyObj() {
        return JSON.parse(sessionStorage.getItem('companyObj'))
    },

    set companyObj(companyObj) {
        sessionStorage.setItem('companyObj', JSON.stringify(companyObj))
    },

    get companyNum() {
        return sessionStorage.getItem('companyNum')
    },

    set companyNum(companyNum) {
        sessionStorage.setItem('companyNum', companyNum)
    },


    get isLogin() {
        return sessionStorage.getItem('isLogin')
    },

    set isLogin(isLogin) {
        sessionStorage.setItem('isLogin', isLogin)
    },
    get city() {
        return sessionStorage.getItem('city')
    },

    set city(city) {
        sessionStorage.setItem('city', city)
    },

    // 管理动画距离顶部偏移量
    get height_top() {
        return sessionStorage.getItem('height_top')
    },

    set height_top(height_top) {
        sessionStorage.setItem('height_top', height_top)
    },

    
    // 管理底部数据
    get footerInfo() {
        return JSON.parse(sessionStorage.getItem('footerInfo'))
    },

    set footerInfo(footerInfo) {
        sessionStorage.setItem('footerInfo', JSON.stringify(footerInfo))
    },
}