// 引入
import Vue from 'vue'
import VueRouter from 'vue-router'
import storage from '@/utils/storage'
// import staticRoutes from './routerMap/staticRoutes'
// import store from '@/store'

// 相同路由不可跳转
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err);
}

// 注册
Vue.use(VueRouter)


// 刷新页面后，立即从本地寻找账号角色
// 如果账号角色存在，则立即通过角色过滤routes
// let role = storage.role;
// if(role){
//     store.commit('permission/FILTER_ROUTES',role)
// }

// 实例
// export function createRouter(routes) {
//     return new VueRouter({
//         routes
//     })
// }

// 定义组件规则
const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import('@/views/Home/Home'),
        meta: {
            title: '首页',
            isLogin: true,
        }
    },
    {
        path: '/construct',
        name: 'construct',
        component: () => import('@/views/Construct/Construct'),
        meta: {
            title: '网站建设',
            isLogin: true,
        }
    },
    
    {
        path: '/applet',
        name: 'applet',
        component: () => import('@/views/Applet/Applet'),
        meta: {
            title: '小程序开发',
            isLogin: true,
        }
    },
    {
        path: '/official',
        name: 'official',
        component: () => import('@/views/Official/Official'),
        meta: {
            title: '公众号开发',
            isLogin: true,
        }
    },
    {
        path: '/app',
        name: 'app',
        component: () => import('@/views/App/App'),
        meta: {
            title: 'App开发',
            isLogin: true,
        }
    },
    {
        path: '/sucsess',
        name: 'sucsess',
        component: () => import('@/views/Sucsess/Sucsess'),
        meta: {
            title: '成功案例',
            isLogin: true,
        }
    },
    {
        path: '/sucsessdetails',
        name: 'sucsessdetails',
        component: () => import('@/views/SucsessDetails/SucsessDetails'),
        meta: {
            title: '案例详情',
            isLogin: true,
        }
    },
    
    {
        path: '/news',
        name: 'news',
        component: () => import('@/views/News/News'),
        meta: {
            title: '新闻资讯',
            isLogin: true,
        }
    },
    {
        path: '/newsdetails',
        name: 'newsdetails',
        component: () => import('@/views/NewsDetails/NewsDetails'),
        meta: {
            title: '新闻详情',
            isLogin: true,
        }
    },
    {
        path: '/about',
        name: 'about',
        component: () => import('@/views/About/About'),
        meta: {
            title: '关于我们',
            isLogin: true,
        }
    },
    {
        path: '/customized',
        name: 'customized',
        component: () => import('@/views/Customized/Customized'),
        meta: {
            title: '系统定制',
            isLogin: true,
        }
    },
    
    
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/Login/Login'),
        meta: {
            title: '登录',
            isLogin: true,
        }
    },

    {
        path: '/404',
        component: () => import('@/views/404/404'),
        meta: {
            title: '',
            isLogin: true,
        }
    },
    {
        path: '*',
        redirect: '/',
        meta: {
            title: '',
            isLogin: true,
        }
    }
]

// 创建路由实例
const router = new VueRouter({
    routes,
    // mode: 'history',
    scrollBehavior (to, from, savedPosition) {
        // return 期望滚动到哪个的位置
        // vue2.0  x  y  控制
        // vue3.0  left  top 控制
        return { left: 0, top: 0 }  }
})

// const router = createRouter(staticRoutes)

// 设置全局前置守卫
/* 
    - to
        - 目标路由
    - from
        - 起始路由
    - next
        - 放行
        - 重定向
*/
router.beforeEach((to, from, next) => {
    // 重定向
    // let routes = router.options.routes
    // routes.forEach(item => {
    //     if (storage.isLogin !== undefined && storage.isLogin !== null && storage.isLogin == '0') {
    //         if (item.meta.isLogin) {
    //             next()
    //         } else {
    //             router.replace('/')
    //         }
    //     } else {
    //         // console.log(3);
    //     }
    // })

    // if (storage.isLogin !== undefined && storage.isLogin !== null && storage.isLogin == '1') {
    //     next()
    // } else {
    //     if (!to.meta.isLogin) {
    //         next('/')
    //         // router.replace('/')
    //     } else {
    //         // router.replace('/')
    //         next()
    //     }
    // }
    window.scrollTo(0, 0)
    next()
})

export default router

// 做路由权限筛选
// store.commit('permission/PERMISSION_ROUTES')